const state = {
  //设置声漫观看集数缓存
  comicsVideoList: {},
  novelSetting: {}, // 小说设置
  comicsSetting: {} // 漫画设置
}
const getters = {
}
const mutations = {
  //设置声漫观看集数缓存
  setComicsVideoList(state, obj) {
    state.comicsVideoList[obj.comicsId] = { mediaId: obj.mediaId }
  },
  // 小说设置
  setNovelSetting(state, obj) {
    state.novelSetting = obj
  },
  // 小说设置
  setComicsSetting(state, obj) {
    state.comicsSetting = obj
  },
}


export default {
  state,
  mutations,
  getters
}
