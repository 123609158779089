/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/',
    component: () => import('@/views/layouts/index'),
    redirect: '/trade',
    meta: {
      title: '首页',
      keepAlive: true,
    },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/index'),
        meta: { title: '浅网', keepAlive: true, hasFooter: true, location: 2 }
      },

      {
        path: '/trade/postTransaction',
        name: 'TradePostTransaction',
        component: () => import('@/views/trade/postTransaction/index'),
        meta: { title: '默认发布页', }
      },
      {
        path: '/trade/postTransaction/jiaoYiPost',
        name: 'TradePostTransactionJiaoYiPost',
        component: () => import('@/views/trade/postTransaction/jiaoYiPost'),
        meta: { title: '交易页发布', }
      },
      {
        path: '/trade/myPost',
        name: 'TradePostmyPost',
        component: () => import('@/views/trade/myPost'),
        meta: { title: '我的发布', }
      },
      {
        path: '/trade',
        name: 'Trade',
        component: () => import('@/views/trade/index'),
        meta: { title: '暗网交易', keepAlive: true, hasFooter: true, location: 2 }
      },
      {
        path: '/post',
        name: 'Post',
        component: () => import('@/views/post/index'),
        meta: { title: '社区', keepAlive: true, hasFooter: true, location: 2 }
      },
      {
        path: '/deepWeb',
        name: 'DeepWeb',
        component: () => import('@/views/deepWeb/index'),
        meta: { title: '深网', keepAlive: true, hasFooter: true, location: 2 }
      },

      {
        path: '/subpage/morePage',
        name: 'subpageMorePage',
        component: () => import('@/views/subpage/morePage/index'),
        meta: { title: '主题详情页', noHasHeader: true }
      },
      {
        path: '/subpage/labelPage',
        name: 'subpageLabelPage',
        component: () => import('@/views/subpage/labelPage/index'),
        meta: { title: '标签详情页', noHasHeader: true }
      },
      {
        path: '/subpage/search',
        name: 'subpageSearch',
        component: () => import('@/views/subpage/search/index'),
        meta: { title: '搜索页', noHasHeader: true }
      },
      {
        path: '/subpage/search/result/:value',
        name: 'subpageResult',
        component: () => import('@/views/subpage/search/result'),
        meta: { title: '搜索视频列表页', noHasHeader: true }
      },


      {
        path: '/subpage/play/longVideo/:id',
        name: 'subpagePlayLongVideo',
        component: () => import('@/views/subpage/play/longVideo/index'),
        meta: { title: '长视频播放页', noHasHeader: true }
      },
      {
        path: '/subpage/play/shortVideo/:id',
        name: 'subpagePlayShortVideo',
        component: () => import('@/views/subpage/play/shortVideo/index'),
        meta: { title: '短视频播放页', noHasHeader: true }
      },


      {
        path: '/community/detail/:id',
        name: 'CommunityDetail',
        component: () => import('@/views/community/detail'),
        meta: { title: '帖子详情', noHasHeader: true }
      },
      {
        path: '/community/public',
        name: 'CommunityPublic',
        component: () => import('@/views/community/public'),
        meta: { title: '帖子发布', noHasHeader: true, keepAlive: true }
      },
      {
        path: '/community/typeList',
        name: 'CommunityTypeList',
        component: () => import('@/views/community/typeList'),
        meta: { title: '选择标签', noHasHeader: true }
      },
      {
        path: '/community/tagDteail',
        name: 'CommunityTagDteail',
        component: () => import('@/views/community/tagDteail'),
        meta: { title: '标签详情', }
      },

      {
        path: '/ai/facePhoto',
        name: 'facePhoto',
        component: () => import('@/views/ai/facePhoto/facePhoto'),
        meta: { title: 'AI换脸（图片）', }
      },
      {
        path: '/ai/generate',
        name: 'generate',
        component: () => import('@/views/ai/generate'),
        meta: { title: 'AI生成 ', noHasHeader: true }
      },
      {
        path: '/ai/myGenerate',
        name: 'myGenerate',
        component: () => import('@/views/ai/myGenerate'),
        meta: { title: '我的AI生成 ', }
      },
      {
        path: '/ai/changeFace',
        name: 'changeFace',
        component: () => import('@/views/ai/facePhoto/changeFace'),
        meta: { title: 'AI换脸', }
      },
      {
        path: '/ai/uploadFace',
        name: 'uploadFace',
        component: () => import('@/views/ai/facePhoto/uploadFace'),
        meta: { title: 'AI脱衣', }
      },
      {
        path: '/mine',
        name: 'Mine',
        component: () => import('@/views/mine/index'),
        meta: { title: '我的', keepAlive: false, hasFooter: true, noHasHeader: true }
      },

      // 交流群组
      {
        path: '/mine/group',
        name: 'MyGroupList',
        component: () => import('@/views/mine/group/index'),
        meta: { title: '交流群', keepAlive: false, noHasHeader: true }
      },
      // 观看记录
      {
        path: '/mine/history',
        name: 'MyHistory',
        component: () => import('@/views/mine/history/index'),
        meta: { title: '交流群', keepAlive: false, noHasHeader: true }
      },
      // 收藏
      {
        path: '/mine/collect',
        name: 'MyCollect',
        component: () => import('@/views/mine/collect/index'),
        meta: { title: '交流群', keepAlive: false, noHasHeader: true }
      },
      // 购买记录
      {
        path: '/mine/buy-history',
        name: 'MyBuyHistory',
        component: () => import('@/views/mine/buy-history/index'),
        meta: { title: '交流群', keepAlive: false, noHasHeader: true }
      },
      // 我的发布
      {
        path: '/mine/my-publish',
        name: 'MyPublic',
        component: () => import('@/views/mine/my-publish/index'),
        meta: { title: '交流群', keepAlive: false, noHasHeader: true }
      },
      // 更换头像
      {
        path: '/mine/setting/avatar',
        name: 'MySettingAvatar',
        component: () => import('@/views/mine/setting/avatar'),
        meta: { title: '更换头像', keepAlive: false, noHasHeader: true }
      }, // 绑定手机
      {
        path: '/mine/setting/bindPhone',
        name: 'MySettingBindPhone',
        component: () => import('@/views/mine/setting/bindPhone'),
        meta: { title: '绑定手机', keepAlive: false, noHasHeader: true }
      },
      // 手机号登陆
      {
        path: '/mine/setting/phone',
        name: 'MySettingPhone',
        component: () => import('@/views/mine/setting/phoneLogin'),
        meta: { title: '手机号登录', keepAlive: false, noHasHeader: true }
      },
      // 个人凭证
      {
        path: '/mine/setting/accoutCode',
        name: 'MySettingAccoutCode',
        component: () => import('@/views/mine/setting/accoutCode'),
        meta: { title: '个人凭证', keepAlive: false, noHasHeader: true }
      },
      // 找回账号
      {
        path: '/mine/setting/accout',
        name: 'MySettingAccout',
        component: () => import('@/views/mine/setting/accout'),
        meta: { title: '找回账号', keepAlive: false, noHasHeader: true }
      },// 在线客服
      {
        path: '/mine/setting/kf',
        name: 'MySettingKf',
        component: () => import('@/views/mine/setting/kf'),
        meta: { title: '在线客服', keepAlive: false, noHasHeader: true }
      },

      // 会员金币购买页
      {
        path: '/mine/newRecharge',
        name: 'NewRecharge',
        component: () => import('@/views/mine/newRecharge/index'),
        meta: { title: '购买', keepAlive: false, noHasHeader: true }
      },
      // 代理赚钱
      {
        path: '/mine/agent',
        name: 'MineAgent',
        component: () => import('@/views/mine/agent/index'),
        meta: { title: '分享赚钱', keepAlive: false, noHasHeader: true }
      },  // 分享邀请
      {
        path: '/mine/share',
        name: 'MineShare',
        component: () => import('@/views/mine/share/index'),
        meta: { title: '分享邀请', keepAlive: false, noHasHeader: true }
      },
      // 流水记录
      {
        path: '/mine/recharge/record',
        name: 'RechargeRecord',
        component: () => import('@/views/mine/recharge/record'),
        meta: { title: '流水记录', keepAlive: false, noHasHeader: true }
      },
      // 申请提现
      {
        path: '/mine/withdraw',
        name: 'MineWithdraw',
        component: () => import('@/views/mine/withdraw/index'),
        meta: { title: '申请提现', keepAlive: false, noHasHeader: true }
      },// 我的关注
      {
        path: '/mine/follwe',
        name: 'MyFollwe',
        component: () => import('@/views/mine/follwe/index'),
        meta: { title: '我的关注', keepAlive: false, noHasHeader: true }
      },
      // 消息中心
      {
        path: '/mine/msg',
        name: 'MyMsg',
        component: () => import('@/views/mine/msg/index'),
        meta: { title: '消息中心', keepAlive: false, noHasHeader: true }
      },
      // 应用中心
      {
        path: '/application',
        name: 'Application',
        component: () => import('@/views/application/index'),
        meta: { title: '应用中心', keepAlive: true, noHasHeader: true }
      },
      // 应用中心
      {
        path: '/mine/feedback',
        name: 'Feedback',
        component: () => import('@/views/mine/feedback/index'),
        meta: { title: '意见反馈', keepAlive: false, noHasHeader: true }
      },
      {
        path: '/mine/myInfo',
        name: 'myInfo',
        component: () => import('@/views/mine/myInfo'),
        meta: { title: '用户列表', keepAlive: true }
      },
      {
        path: '/mine/checkIn',
        name: 'CheckIn',
        component: () => import('@/views/mine/checkIn/index'),
        meta: { title: '签到', keepAlive: false, noHasHeader: true }
      }, // 消息详情
      {
        path: '/mine/checkIn/rule',
        name: 'CheckInRule',
        component: () => import('@/views/mine/checkIn/rule'),
        meta: { title: '签到规则', keepAlive: false, noHasHeader: true }
      }, // 消息详情
      {
        path: '/mine/checkIn/checkRedeem',
        name: 'checkRedeem',
        component: () => import('@/views/mine/checkIn/checkRedeem'),
        meta: { title: '积分兑换记录', keepAlive: false, noHasHeader: true }
      }, // 消息详情
      {
        path: '/mine/msg/ads',
        name: 'MyMsgAds',
        component: () => import('@/views/mine/msg/ads'),
        meta: { title: '消息详情', keepAlive: false, noHasHeader: true }
      },
      {
        path: '/mine/msg/msgDetail',
        name: 'msgDetail',
        component: () => import('@/views/mine/msg/msgDetail'),
        meta: { title: '评论&回复', keepAlive: false, noHasHeader: true }
      },
      // 设置中心
      {
        path: '/mine/setting',
        name: 'MySetting',
        component: () => import('@/views/mine/setting/index'),
        meta: { title: '设置中心', keepAlive: false, noHasHeader: true }
      },
      // 兑换码
      {
        path: '/mine/redemption-code',
        name: 'MyRedemptionCode',
        component: () => import('@/views/mine/redemption-code/index'),
        meta: { title: '兑换码', keepAlive: false, noHasHeader: true }
      },

      {
        path: '/mine/lottery',
        name: 'Lottery',
        component: () => import('@/views/mine/lottery/index'),
        meta: { keepAlive: false, hasFooter: false, noHasHeader: true }
      },
      {
        path: '/mine/lottery/history',
        name: 'LotHistory',
        component: () => import('@/views/mine/lottery/history.vue'),
        meta: { title: '中奖记录', keepAlive: false, hasFooter: false, noHasHeader: true }
      },
      // 二维码扫描页
      {
        path: '/mine/setting/accoutCode-camera',
        name: 'AccoutCodeCamera',
        component: () => import('@/views/mine/setting/accoutCode-camera'),
        meta: { keepAlive: false, hasFooter: false, noHasHeader: true }
      },// 聊天室
      {
        path: '/chatGroupIM',
        name: 'ChatGroupIM',
        component: () => import('@/views/chatGroupIM/index.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/chatIM/:id',
        name: 'ChatIM',
        component: () => import('@/views/chatIM/index.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/Activity/lottery/list',
        name: 'Activity',
        component: () => import('@/views/Activity/lottery/list.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/Activity/lottery/detail/:value',
        name: 'ActivityDetail',
        component: () => import('@/views/Activity/lottery/detail/index.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      //漫画详情
      {
        path: '/home-comic/decial/:id',
        name: 'HomeComicDetail',
        component: () => import('@/views/home-comic/decial/_id.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      // 漫画预览页面/home-comic/pic-view/
      {
        path: '/home-comic/pic-view/:id',
        name: 'HomeComicPic',
        component: () => import('@/views/home-comic/pic-view/_id.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      // bookshelf 书架
      {
        path: '/home-comic/bookshelf',
        name: 'HomeComicBook',
        component: () => import('@/views/home-comic/bookshelf/index.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/home-comic/evaluation/:id',
        name: 'HomeComicEvaluation',
        component: () => import('@/views/home-comic/Evaluation/_id.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      // /home-comic/more
      {
        path: '/home-comic/more',
        name: 'HomeComicMore',
        component: () => import('@/views/home-comic/more/index.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/home-comic/tag-type-one',
        name: 'HomeComicTagone',
        component: () => import('@/views/home-comic/tag-type-one/index.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/home-comic/tag-type',
        name: 'HomeComicTag',
        component: () => import('@/views/home-comic/tag-type/index.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/home-comic/author',
        name: 'HomeComicauthor',
        component: () => import('@/views/home-comic/author/index.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/mine/otherUserInfo/:id',
        name: 'otherUserInfo',
        component: () => import('@/views/mine/otherUserInfo/index.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/dating/city-list',
        name: 'citySelList',
        component: () => import('@/views/dating/city-list.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/dating/model-decial/:id',
        name: 'modelDecial',
        component: () => import('@/views/dating/model-decial/_id.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/dating/bosses-detail/:id',
        name: 'bossesDecial',
        component: () => import('@/views/dating/bosses-detail/_id.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/dating/report/:id',
        name: 'reportDecial',
        component: () => import('@/views/dating/report/_id.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/dating/publish',
        name: 'publishGirl',
        component: () => import('@/views/dating/publish/index.vue'),
        meta: { hasFooter: false, noHasHeader: true, keepAlive: true, }
      },

      //小说详情
      {
        path: '/home-novel/decial/:id',
        name: 'HomeNovelDetail',
        component: () => import('@/views/home-novel/decial/_id.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      // 小说预览页面/home-novel/pic-view/
      {
        path: '/home-novel/word-view/:id',
        name: 'HomeNovelwordView',
        component: () => import('@/views/home-novel/word-view/_id.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/home-novel/audioPlay',
        name: 'HomeNovelAudioPlay',
        component: () => import('@/views/home-novel/audioPlay/index.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      // /home-novel/more
      {
        path: '/home-novel/more',
        name: 'HomeNovelMore',
        component: () => import('@/views/home-novel/more/index.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/home-novel/more/list',
        name: 'HomeNovelMoreList',
        component: () => import('@/views/home-novel/more/list.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/home-novel/tagOne',
        name: 'HomeNovelTagone',
        component: () => import('@/views/home-novel/tagOne/index.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/home-novel/auther',
        name: 'HomeNovelauther',
        component: () => import('@/views/home-novel/auther/index.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
      {
        path: '/photoDetail/:id',
        name: 'photoDetail',
        component: () => import('@/views/photoDetail/_id.vue'),
        meta: { hasFooter: false, noHasHeader: true }
      },
    ]
  }
]
