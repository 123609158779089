import api from './index'
// axios
import request from '@/utils/request'

// 游客登录
export function ykInfo(data) {
  return request({
    url: api.ykInfo,
    method: 'post',
    data
  })
}

// 获取用户信息
export function userInfo(data) {
  return request({
    url: api.userInfo,
    method: 'post',
    data
  })
}
// 获取账号凭证
export function userQrcode(data) {
  return request({
    url: api.userQrcode,
    method: 'post',
    data
  })
}
// 交易记录
export function transList(data) {
  return request({
    url: api.transList,
    method: 'post',
    data
  })
}
// 充值记录 
export function recharge_list(data) {
  return request({
    url: api.recharge_list,
    method: 'post',
    data
  })
}
// 更新信息
export function update_info(data) {
  return request({
    url: api.update_info,
    method: 'post',
    data
  })
}
// 用户头像
export function user_avatar(data) {
  return request({
    url: api.user_avatar,
    method: 'post',
    data
  })
}
// 关注列表
export function care_list(data) {
  return request({
    url: api.care_list,
    method: 'post',
    data
  })
}
// 粉丝列表
export function care_fans(data) {
  return request({
    url: api.care_fans,
    method: 'post',
    data
  })
}
// 使用兑换码
export function redeemcode_user(data) {
  return request({
    url: api.redeemcode_user,
    method: 'post',
    data
  })
}

// 兑换记录
export function redeemcode_list(data) {
  return request({
    url: api.redeemcode_list,
    method: 'post',
    data
  })
}
// 消息列表
export function message_list(data) {
  return request({
    url: api.message_list,
    method: 'post',
    data
  })
}
// 私信列表
export function message_dialog(data) {
  return request({
    url: api.message_dialog,
    method: 'post',
    data
  })
}
// 提现信息
export function withdrawal_info(data) {
  return request({
    url: api.withdrawal_info,
    method: 'post',
    data
  })
}
// 申请提现
export function withdrawal_submit(data) {
  return request({
    url: api.withdrawal_submit,
    method: 'post',
    data
  })
}
// 用户代理信息
export function user_income(data) {
  return request({
    url: api.user_income,
    method: 'post',
    data
  })
}

// 用户收益明细
export function income_detail(data) {
  return request({
    url: api.income_detail,
    method: 'post',
    data
  })
}

// 交流群组
export function config_contact(data) {
  return request({
    url: api.config_contact,
    method: 'post',
    data
  })
}
// 我的发布列表
export function publish_list(data) {
  return request({
    url: api.publish_listV2,
    method: 'post',
    data
  })
}

// 我的发布列表
export function publish_listV2(data) {
  return request({
    url: api.publish_listV2,
    method: 'post',
    data
  })
}
// 批量删除我的发布
export function publish_del(data) {
  return request({
    url: api.publish_del,
    method: 'post',
    data
  })
}
// 我的收藏列表
export function collect_list(data) {
  return request({
    url: api.collect_list,
    method: 'post',
    data
  })
}
// 批量删除我的发布
export function collect_del(data) {
  return request({
    url: api.collect_del,
    method: 'post',
    data
  })
}
// 我的购买列表
export function pay_history(data) {
  return request({
    url: api.pay_history,
    method: 'post',
    data
  })
}
// 获取私信信息
export function msg_info(data) {
  return request({
    url: api.msg_info,
    method: 'post',
    data
  })
}
// 发送
export function msg_send(data) {
  return request({
    url: api.msg_send,
    method: 'post',
    data
  })
}
// 扫描二维码找回用户信息
export function user_qrcode_info(data) {
  return request({
    url: api.user_qrcode_info,
    method: 'post',
    data
  })
}

export function getLotteryList(data) {
  return request({
    url: api.getLotteryList,
    method: 'post',
    data
  })
}
export function getLotteryListAll(data) {
  return request({
    url: api.getLotteryListAll,
    method: 'post',
    data
  })
}

export function getLotteryRedeem(data) {
  return request({
    url: api.getLotteryRedeem,
    method: 'post',
    data
  })
}
export function getLotteryRedeemHistory(data) {
  return request({
    url: api.getLotteryRedeemHistory,
    method: 'post',
    data
  })
}

export function checkLottery(data) {
  return request({
    url: api.checkLottery,
    method: 'post',
    data
  })
}

export function lotteryHistory(data) {
  return request({
    url: api.lotteryHistory,
    method: 'post',
    data
  })
}
// 提现列表 withdrawal_list
export function withdrawal_list(data) {
  return request({
    url: api.withdrawal_list,
    method: 'post',
    data
  })
}
// 意见反馈
export function feedback_add(data) {
  return request({
    url: api.feedback_add,
    method: 'post',
    data
  })
}

// 分享邀请-获取代理配置
export function get_proxy_config(data) {
  return request({
    url: api.proxy_config,
    method: 'post',
    data
  })
}
// 注册 /login/account/register

export function account_register_api(data) {
  return request({
    url: api.account_register_api,
    method: 'post',
    data
  })
}
export function checkin_setup(data) {
  return request({
    url: api.checkin_setup,
    method: 'post',
    data
  })
}
export function checkin_page(data) {
  return request({
    url: api.checkin_page,
    method: 'post',
    data
  })
}
export function prize_redeem(data) {
  return request({
    url: api.prize_redeem,
    method: 'post',
    data
  })
}
export function prize_redeemhistory(data) {
  return request({
    url: api.prize_redeemhistory,
    method: 'post',
    data
  })
}

export function checkin_click(data) {
  return request({
    url: api.checkin_click,
    method: 'post',
    data
  })
}
export function userIsCert(data) {
  return request({
    url: api.userIsCert,
    method: 'post',
    data
  })
}
export function getMsInfo(data) {
  return request({
    url: api.getMsInfo,
    method: 'post',
    data
  })
}
export function getMsSend(data) {
  return request({
    url: api.getMsSend,
    method: 'post',
    data
  })
}

