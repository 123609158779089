import api from './index'
// axios
import request from '@/utils/request'

// 小说
export function novelHome(data) {
  return request({
    url: api.novelHome,
    method: 'post',
    data
  })
}

// 小说详情
export function novelDetail(data) {
  return request({
    url: api.novelDetail,
    method: 'post',
    data
  })
}

// 购买小说
export function novelPay(data) {
  return request({
    url: api.novelPay,
    method: 'post',
    data
  })
}

// 小说作者作品
export function novelAuthor(data) {
  return request({
    url: api.novelAuthor,
    method: 'post',
    data
  })
}

// 小说换一换
export function novelChange(data) {
  return request({
    url: api.novelChange,
    method: 'post',
    data
  })
}

// 小说章节内容
export function novelInfo(data) {
  return request({
    url: api.novelInfo,
    method: 'post',
    data
  })
}

// 小说章节是否可以直接观看
export function novelIsLook(data) {
  return request({
    url: api.novelIsLook,
    method: 'post',
    data
  })
}

//  小说分类标签
export function novelTagList(data) {
  return request({
    url: api.novelTagList,
    method: 'post',
    data
  })
}

// 小说分类标签
export function noveListByTag(data) {
  return request({
    url: api.noveListByTag,
    method: 'post',
    data
  })
}

// 小说更多
export function moreList(data) {
  return request({
    url: api.moreList,
    method: 'post',
    data
  })
}

// 小说作者
export function authorByList(data) {
  return request({
    url: api.authorByList,
    method: 'post',
    data
  })
}


export function novelTrackList(data) {
  return request({
    url: api.novelTrackList,
    method: 'post',
    data
  })
}


export function novelChapterInfo(data) {
  return request({
    url: api.novelChapterInfo,
    method: 'post',
    data
  })
}
