
import request from '@/utils/request'
let BASEURL
const apiType = 'app'
apiType === 'h5app' ? BASEURL = '/api/h5app' : BASEURL = '/api/app'

// 漫画首页
export function comicsHome(data) {
  return request({
    url: BASEURL + '/comics/home',
    method: 'post',
    data
  })
}
// 漫画详情
export function comicsDetail(data) {
  return request({
    url: BASEURL + '/comics/detail',
    method: 'post',
    data
  })
}

// 漫画换一换
export function comicsTopicChange(data) {
  return request({
    url: BASEURL + '/comicsTopic/change',
    method: 'post',
    data
  })
}

// 漫画购买
export function comicsTopicCtPay(data) {
  return request({
    url: BASEURL + '/comicsTopic/ctPay',
    method: 'post',
    data
  })
}

// 视频更多
export function mediaTopicList(data) {
  return request({
    url: BASEURL + '/media/topic/details', // 视频更多
    method: 'post',
    data
  })
}

// 标签视频列表
export function labelTopicList(data) {
  return request({
    url: BASEURL + '/media/tag/details', // 标签视频列表
    method: 'post',
    data
  })
}

// 作者作品
export function comicsAuthor(data) {
  return request({
    url: BASEURL + '/comics/author', // 漫画作者作品,
    method: 'post',
    data
  })
}

// 标签作品
export function comicsTagListById(data) {
  return request({
    url: BASEURL + '/comicsTag/listById', // 标签作品
    method: 'post',
    data
  })
}

// 漫画评论
export function comCommentList(data) {
  return request({
    url: BASEURL + '/comment/list', // 评论列表
    method: 'post',
    data
  })
}

// 添加评论
export function comCommentAdd(data) {
  return request({
    url: BASEURL + '/comment/add', // 添加评论,
    method: 'post',
    data
  })
}

// 漫画查看权限
export function comicsChapterIsLook(data) {
  return request({
    url: BASEURL + '/comicsChapter/isLook', // 漫画查看权限
    method: 'post',
    data
  })
}

//漫画支付
export function comicsPay(data) {
  return request({
    url: BASEURL + '/comics/pay', // 漫画支付
    method: 'post',
    data
  })
}

//漫画再次较验
export function comicsChapterPics(data) {
  return request({
    url: BASEURL + '/comicsChapter/pics', // 漫画再次较验,
    method: 'post',
    data
  })
}

// 书架 
export function trackList(data) {
  return request({
    url: BASEURL + '/comics/trackList',
    method: 'post',
    data
  })
}

// 加入书架
export function collectAdd(data) {
  return request({
    url: BASEURL + '/collect/add',
    method: 'post',
    data
  })
}

//取消收藏
export function collectDel(data) {
  return request({
    url: BASEURL + '/collect/del',
    method: 'post',
    data
  })
}

// 评分 /votecomment/add
export function addScore(data) {
  return request({
    url: BASEURL + '/votecomment/add',
    method: 'post',
    data
  })
}

//comicsTopicList
export function comicsTopicList(data) {
  return request({
    url: BASEURL + '/comicsTopic/list',
    method: 'post',
    data
  })
}

//
export function comicsTagAll(data) {
  return request({
    url: BASEURL + '/comicsTag/all',
    method: 'post',
    data
  })
}
