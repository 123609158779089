// 按需全局引入 vant组件
import Vue from 'vue'
import {
  Lazyload, Toast, Notify, Dialog, ImagePreview,
  ShareSheet,
  Sticky,
  CountDown,
  Radio,
  RadioGroup,
  NoticeBar,
  Popover,
  NavBar,
  List,
  Tabbar,
  TabbarItem,
  Swipe,
  SwipeItem,
  Tag,
  Icon,
  Search,
  Form,
  Field,
  Button,
  Checkbox,
  Overlay,
  CheckboxGroup,
  Empty,
  PullRefresh,
  Uploader,
  Skeleton,
  // Row,
  // Col,
  Tabs,
  Tab,
  CellGroup,
  // Image,
  Popup,
  // Picker,
  Cell,
  Sidebar,
  SidebarItem,
  Rate,
  Grid,
  GridItem,
  IndexBar,
  IndexAnchor,
  Collapse,
  ActionSheet,
  CollapseItem,
  Slider,
  DropdownMenu,
  DropdownItem,
  Stepper,
  Area,
  Loading,
  Switch
} from 'vant'
Vue.use(Switch)
Vue.use(Area)
Vue.use(Sidebar)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(SidebarItem)
Vue.use(Collapse)
Vue.use(IndexBar)
Vue.use(IndexAnchor)
Vue.use(CollapseItem)
Vue.use(Slider)
Vue.use(Overlay)
Vue.use(Sticky)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(NoticeBar)
Vue.use(NavBar)
Vue.use(List)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Tag)
Vue.use(Icon)
Vue.use(Search)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Empty)
Vue.use(PullRefresh)
Vue.use(Loading)
Vue.use(Skeleton)
Vue.use(ImagePreview)
Vue.use(ActionSheet)
// Vue.use(Row)
// Vue.use(Col)
Vue.use(Tabs)
Vue.use(Tab)
Vue.use(CountDown)
Vue.use(CellGroup)
// Vue.use(Image)
Vue.use(Uploader)
Vue.use(Popup)
// Vue.use(Picker)
Vue.use(Cell)
Vue.use(Rate)
Vue.use(Popover)
Vue.use(ShareSheet)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Dialog)
Vue.use(Stepper)
Vue.prototype.$toast = Toast
Vue.prototype.$notify = Notify
Vue.prototype.$Dialog = Dialog
Vue.use(Lazyload, {
  lazyComponent: true,
  attempt: 1,
  observer: true,
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1
  },
  listenEvents: ['scroll'],
  adapter: {
    loaded({ bindType, el, naturalHeight, naturalWidth, $parent, src, loading, error, Init }) {
      // do something here
      // example for call LoadedHandler
      console.log('loaded')
    },
    loading(listender, Init) {
      console.log('loading')
    },
    error(listender, Init) {
      console.log('error')
    }
  },

})
