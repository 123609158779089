const state = {
  // 搜索历史列表
  saerchlist: [],
  // 长视频列表
  avList: [],
  // 短视频列表
  shortList: [],
  // 动漫视频列表
  cartoonList: [],
  longVideoList: [],
  // 暗黑游列表
  aHYlist: [],
  // 帝王调教列表
  dWTJlist: [],
  // 暗网交易列表
  aWJYlist: [],
  postList: [],
  // av分类默认index
  avIndex: 0,
  // 小视频分类默认index
  shortVideoIndex: 0,
  // 动漫分类默认index
  cartoonIndex: 0,
  // 社区分类默认index
  communityIndex: 0,


  //书架
  bookshelfList: [
    { id: '默认分组', name: '默认分组', list: [] }
  ],
  //个人中心-漫画浏览历史
  comicsHisList: [],
  //新个人中心-漫画浏览历史
  comicsHisMap: {},
  //漫画当前章节保存
  comicChapterObj: {},
  topTabLabelList: [],
  postSelect: {},
  changeFaceItem: {},
  // 个人中心-约炮历史记录
  ypHisList: [],
  novelHisList: [],
  audioPlayMethodIndex: 0,
  photoHisList: []
}
const getters = {
  getChangeFaceItem(state) {
    return state.changeFaceItem
  },
  getSaerchlist(state) {
    return state.saerchlist
  },
  avList(state) {
    return state.avList
  },
  shortList(state) {
    return state.shortList
  },
  aHYlist(state) {
    return state.aHYlist
  },
  dWTJlist(state) {
    return state.dWTJlist
  },
  aWJYlist(state) {
    return state.aWJYlist
  },
  ypHisList(state) {
    return state.ypHisList
  },
  photoHisList(state) {
    return state.photoHisList
  },
  longVideoList(state) {
    return state.longVideoList
  },

  postList(state) {
    return state.postList
  },

  cartoonList(state) {
    return state.cartoonList
  },
  avIndex(state) {
    return state.avIndex
  },
  shortVideoIndex(state) {
    return state.shortVideoIndex
  },
  cartoonIndex(state) {
    return state.cartoonIndex
  },
  communityIndex(state) {
    return state.communityIndex
  },
  topTabLabelList(state) {
    return state.topTabLabelList
  },
  geAudioPlayMethodIndex(state) {
    return state.audioPlayMethodIndex
  },
  novelHisList(state) {
    return state.novelHisList
  },
}

const mutations = {
  setChangeFaceItem(state, item) {
    state.changeFaceItem = item
  },
  //设置书架默认分组最多100个
  setBookshelf(state, list) {
    state.bookshelfList = list;
  },
  setPostSelect(state, postSelect) {
    state.postSelect = postSelect;
  },
  //漫画收藏
  setComicsHisList(state, obj) {
    if (obj.type == "add") {
      let status = state.comicsHisList.some((item) => { return item.id == obj.item.id });
      if (!status) {
        if (state.comicsHisList.length > 50) {
          state.comicsHisList = state.comicsHisList.splice(0, 50);
        };
        state.comicsHisList.unshift(obj.item);
      }
    } else if (obj.type == "delOne") {
      state.comicsHisList.splice(obj.index, 1);
    } else if (obj.type == "delAll") {
      state.comicsHisList = [];
    }
  },
  //漫画当前章节保存
  setComicChapterObj(state, obj) {
    state.comicChapterObj = obj;
  },
  setComicsHisMap(state, obj) {
    const { parentId, id, pageValue } = obj
    if (!state.comicsHisMap[parentId]) {
      state.comicsHisMap[parentId] = {}
    }
    state.comicsHisMap[parentId][id] = pageValue
  },
  // 小说历史
  setNovelHisList(state, item) {
    // if (obj.type == "add") {
    //   let status = state.novelHisList.some((item) => { return item.id == obj.item.id });
    //   if (!status) {
    //     if (state.novelHisList.length > 50) {
    //       state.novelHisList = state.novelHisList.splice(0, 50);
    //     };
    //     state.novelHisList.unshift(obj.item);
    //   }
    // } else if (obj.type == "delOne") {
    //   state.novelHisList = state.novelHisList.filter((o) => {
    //     return !obj.arr.includes(o.id)
    //   });
    // } else if (obj.type == "delAll") {
    //   state.novelHisList = [];
    // }

    if (item.type === 'add') {
      item.item.clickMask = false
      if (state.novelHisList && state.novelHisList.length > 0) {
        state.novelHisList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.novelHisList.splice(sIndex, 1)
          }
        })
        state.novelHisList.unshift(item.item)
      } else {
        state.novelHisList.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.novelHisList = item.arr
    } else {
      state.novelHisList = [...item.item]
    }
  },
  SET_SEARCHLIST(state, item) {
    if (item.type === 'add') {
      if (state.saerchlist.length > 0) {
        state.saerchlist.map((sItme, sIndex) => {
          if (sItme === item.value) {
            state.saerchlist.splice(sIndex, 1)
          }
        })
        state.saerchlist.push(item.value)
      } else {
        state.saerchlist.push(item.value)
      }
    } else if (item.type === 'del') {
      state.saerchlist.splice(item.value, 1)
    } else {
      state.saerchlist = item.value
    }
  },
  // av视频
  SET_AVLIST(state, item) {
    if (item.type === 'add') {
      item.item.clickMask = false
      if (state.avList && state.avList.length > 0) {
        state.avList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.avList.splice(sIndex, 1)
          }
        })
        state.avList.unshift(item.item)
      } else {
        state.avList.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.avList = state.avList.filter((o) => {
        return !item.arr.includes(o.id)
      });
    } else {
      state.avList = [...item.item]
    }
  },



  SET_POSTLIST(state, item) {

    if (item.type === 'add') {
      item.item.clickMask = false
      if (state.postList && state.postList.length > 0) {
        state.postList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.postList.splice(sIndex, 1)
          }
        })
        state.postList.unshift(item.item)
      } else {
        state.postList.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.postList = state.postList.filter((o) => {
        return o.clickMask
      });
    } else {
      state.postList = [...item.item]
    }
  },

  // 短视频
  SET_SHORTLIST(state, item) {
    if (item.type === 'add') {
      item.item.clickMask = false
      if (state.shortList && state.shortList.length > 0) {
        state.shortList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.shortList.splice(sIndex, 1)
          }
        })
        state.shortList.unshift(item.item)
      } else {
        state.shortList.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.shortList = item.arr
    } else {
      state.shortList = [...item.item]
    }
  },

  SET_LONGVIDEOLIST(state, item) {
    if (item.type === 'add') {
      item.item.clickMask = false
      if (state.longVideoList && state.longVideoList.length > 0) {
        state.longVideoList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.longVideoList.splice(sIndex, 1)
          }
        })
        state.longVideoList.unshift(item.item)
      } else {
        state.longVideoList.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.longVideoList = item.arr
    } else {
      state.longVideoList = [...item.item]
    }
  },
  // 动漫
  SET_CARTOON(state, item) {
    if (item.type === 'add') {
      item.item.clickMask = false
      if (state.cartoonList && state.cartoonList.length > 0) {
        state.cartoonList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.cartoonList.splice(sIndex, 1)
          }
        })
        state.cartoonList.push(item.item)
      } else {
        state.cartoonList.push(item.item)
      }
    } else if (item.type === 'del') {
      state.cartoonList = state.cartoonList.filter((o) => {
        return !item.arr.includes(o.id)
      });
    } else {
      state.cartoonList = [...item.item]
    }
  },
  SET_AVINDEX(state, id) {
    state.avIndex = id
  },
  SET_SHORTVIDEOINDEX(state, id) {
    state.shortVideoIndex = id
  },
  SET_CARTOONINDEX(state, id) {
    state.cartoonIndex = id
  },
  SET_COMMUNITYINDEX(state, id) {
    state.communityIndex = id
  },
  SET_TOPTABLABElLIST(state, item) {
    state.topTabLabelList = [...item]
  },
  SET_AHYlist(state, item) {
    if (item.type === 'add') {
      item.item.clickMask = false
      if (state.aHYlist && state.aHYlist.length > 0) {
        state.aHYlist.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.aHYlist.splice(sIndex, 1)
          }
        })
        state.aHYlist.unshift(item.item)
      } else {
        state.aHYlist.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.aHYlist = item.arr
    } else {
      state.aHYlist = [...item.item]
    }
  },
  SET_DWTJlist(state, item) {

    if (item.type === 'add') {
      item.item.clickMask = false
      if (state.dWTJlist && state.dWTJlist.length > 0) {
        state.dWTJlist.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.dWTJlist.splice(sIndex, 1)
          }
        })
        state.dWTJlist.unshift(item.item)
      } else {
        state.dWTJlist.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.dWTJlist = item.arr
    } else {
      state.dWTJlist = [...item.item]
    }
  },
  SET_AWJYlist(state, item) {
    if (item.type === 'add') {
      item.item.clickMask = false
      if (state.aWJYlist && state.aWJYlist.length > 0) {
        state.aWJYlist.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.aWJYlist.splice(sIndex, 1)
          }
        })
        state.aWJYlist.unshift(item.item)
      } else {
        state.aWJYlist.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.aWJYlist = item.arr
    } else {
      state.aWJYlist = [...item.item]
    }
  },
  setYpHisList(state, item) {
    if (item.type === 'add') {
      item.item.clickMask = false
      if (state.ypHisList && state.ypHisList.length > 0) {
        state.ypHisList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.ypHisList.splice(sIndex, 1)
          }
        })
        state.ypHisList.unshift(item.item)
      } else {
        state.ypHisList.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.ypHisList = item.arr
    } else {
      state.ypHisList = [...item.item]
    }
  },
  setAudioPlayMethodIndex(state) {
    if (state.audioPlayMethodIndex < 2) {
      state.audioPlayMethodIndex++
    } else {
      state.audioPlayMethodIndex = 0
    }
  },
  setphotoHisList(state, item) {
    if (item.type === 'add') {
      item.item.clickMask = false
      if (state.photoHisList && state.photoHisList.length > 0) {
        state.photoHisList.map((sItme, sIndex) => {
          if (sItme.id === item.item.id) {
            state.photoHisList.splice(sIndex, 1)
          }
        })
        state.photoHisList.unshift(item.item)
      } else {
        state.photoHisList.unshift(item.item)
      }
    } else if (item.type === 'del') {
      state.photoHisList = item.arr
    } else {
      state.photoHisList = [...item.item]
    }
  }
}
const actions = {

  setPostList({ commit }, item) {
    commit('SET_POSTLIST', item)
  },
  setAvList({ commit }, item) {
    commit('SET_AVLIST', item)
  },
  setShortList({ commit }, item) {
    commit('SET_SHORTLIST', item)
  },
  setLongVideoList({ commit }, item) {
    commit('SET_LONGVIDEOLIST', item)
  },
  setSaerchlist({ commit }, item) {
    commit('SET_SEARCHLIST', item)
  },
  setCartoonList({ commit }, item) {
    commit('SET_CARTOON', item)
  },
  setAvIndex({ commit }, item) {
    commit('SET_AVINDEX', item)
  },
  setAHYlist({ commit }, item) {
    commit('SET_AHYlist', item)
  },
  setDWTJlist({ commit }, item) {
    commit('SET_DWTJlist', item)
  },
  setAWJYlist({ commit }, item) {
    commit('SET_AWJYlist', item)
  },
  setYpHisList({ commit }, item) {
    commit('setYpHisList', item)
  },
  setNovelHisList({ commit }, item) {
    commit('setNovelHisList', item)
  },
  setphotoHisList({ commit }, item) {
    commit('setphotoHisList', item)
  },

}
export default {
  state,
  mutations,
  actions,
  getters
}
